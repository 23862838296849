import "./home.css";

export default function Header() {
  return (
    <div className="header">
      <img
        onClick={() => {
          window.location.href = "/";
        }}
        className="header__logo"
        src="/logo.png"
      />
      <div className="header__nav">
        <span
          className="header__nav__item"
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Home
        </span>
        <span
          onClick={() => {
            window.location.href = "/#services";
          }}
          className="header__nav__item"
        >
          Services
        </span>
        <span
          onClick={() => {
            window.location.href = "/#products";
          }}
          className="header__nav__item"
        >
          Products
        </span>
        <span
          onClick={() => {
            window.location.href = "/career";
          }}
          className="header__nav__item"
        >
          Career
        </span>
      </div>
      <div className="header__action">
        {/* <button className="header__action__signup">Sign up</button> */}
      </div>
    </div>
  );
}
