import "./App.css";
import { useEffect, useState } from "react";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Home from "./company/Home";

function App() {
  const clientId =
    "707354874616-4lo66nhukbqtndthorindd028ispqi3g.apps.googleusercontent.com";
  const redirectUri = "https://3c22-139-5-251-226.ngrok-free.app";
  const scope = "https://www.googleapis.com/auth/contacts.readonly";
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
  const [token, setToken] = useState("");

  const [contacts, setContacts] = useState("");

  useEffect(() => {
    // alert("contacts" in navigator);

    const hash = window.location.hash.substr(1);
    const result = hash.split("&").reduce((res, item) => {
      let parts = item.split("=");
      res[parts[0]] = parts[1];
      return res;
    }, {});

    if (result.access_token) {
      setToken(result.access_token);
    }
  }, []);

  const props = ["name", "email", "tel", "address", "icon"];
  const opts = { multiple: true };

  async function getContacts() {
    try {
      const contacts = await navigator.contacts.select(props, opts);
      setContacts(JSON.stringify(contacts));
    } catch (ex) {
      // Handle any errors here.
    }
  }

  const handleLogin = () => {
    const authUrl = `${oauth2Endpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${scope}`;
    console.log(authUrl);
    window.location = authUrl;
  };

  const fetchContacts = () => {
    fetch(
      "https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses,phoneNumbers",
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log({ res });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return <Home />;
}

export default App;
