import { useEffect, useRef, useState } from "react";

export default function TextUpAnimate({ children, className }) {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              console.log(" >>> ");
              setShow(true);

              //   observer.unobserve(entry.target);
            }
          });
        },
        { rootMargin: "0px", threshold: 0.5 }
      );

      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={`${show && "textUpAnimation"} ${className}`}
    >
      {children}
    </div>
  );
}
