import { useEffect, useRef } from "react";
import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import Privacy from "./Privacy";
import Products from "./Products";
import Services from "./Services";
import Team from "./Team";
import Terms from "./Terms";
import "./home.css";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Hiring from "./Hiring";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div className="home" id="home">
        <Header />
        <Banner />
        <Services />
        <Products />
        <Footer />
      </div>
    ),
  },
  {
    path: "terms",
    element: (
      <div className="home" id="home">
        <Header />
        <Terms />
        <Footer />
      </div>
    ),
  },
  {
    path: "privacy",
    element: (
      <div className="home" id="home">
        <Header />
        <Privacy />
        <Footer />
      </div>
    ),
  },
  {
    path: "career",
    element: (
      <div className="home" id="home">
        <Header />
        <Hiring />
        <Footer />
      </div>
    ),
  },
  {
    path: "*",
    element: (
      <div className="notFound">
        <h3>No page found</h3>
        <a href="/">Go back to home</a>
      </div>
    ),
  },
]);

export default function Home() {
  useEffect(() => {
    document.title = "Sowiz";
  }, []);

  return <RouterProvider router={router} />;
}
