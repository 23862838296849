import "./home.css";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footerBackground">
        <img src="/footerback.png" />
        <div className="footer_contact">
          <div className="footer_contact__text">
            <div className="footer_contact__title">
              Is Your Business Prepared to Elevate Customer Engagement to the
              Next Level?
            </div>
            <button
              onClick={() => {
                window.location.href = "mailto:contact@sowiz.live";
              }}
              className="footer_contact__action"
            >
              Contacts Us
            </button>
          </div>
          <img src="/contactbanner.gif" className="footer_contact__image" />
        </div>
        <div className="footer_links">
          <div className="footer_links__left">© 2024 All rights reserved</div>
          <div className="footer_links__right">
            <div className="footer_links__right__legal">
              {/* <span className="footer_links__right__legal__title">Legal</span> */}
              <span
                onClick={() => {
                  window.location.href = "/career";
                }}
                className="footer_links__right__legal__link"
              >
                Careers
              </span>
              <span
                onClick={() => {
                  window.location.href = "/terms";
                }}
                className="footer_links__right__legal__link"
              >
                Terms & Conditions
              </span>
              <span
                onClick={() => {
                  window.location.href = "/privacy";
                }}
                className="footer_links__right__legal__link"
              >
                Privacy Policy
              </span>
              <span
                onClick={() => {
                  window.location.href = "mailto:contact@sowiz.live";
                }}
                className="footer_links__right__legal__link"
              >
                Contact us
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
