import { useEffect, useState } from "react";
import ScreenHeight90 from "../ScreenHeightContainer";
import "./home.css";

export default function Banner() {
  const [ratio, setRatio] = useState(0.6);

  useEffect(() => {
    if (window.innerWidth < 600) {
      setRatio(0.7);
    }
  }, []);

  return (
    <ScreenHeight90 ratio={ratio} className="banner">
      <div className="banner__text">
        <div className="banner__text__title">
          Welcome to the Era of <strong>Actionable Influence</strong>
        </div>
        <div className="banner__text__subtitle">
          Fueled by AI, we empower businesses to convey the best messaging about
          their products while helping customers gain deep insights through
          AI-powered social wisdom. We create personalized, super-fast content
          consumption at scale, transforming the landscape of decision-making by
          enabling quick, informed choices.
        </div>

        <img className="banner__text__img" src="./home.gif" />
        <div className="banner__text__action">
          <button
            onClick={() => {
              window.location.href = "mailto:contact@sowiz.live";
            }}
            className="banner__text__action__contact"
          >
            Contact us
          </button>
          <button
            className="banner__text__action__getStarted"
            onClick={() => {
              document.getElementById("services").scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "nearest",
              });
            }}
          >
            Get started
          </button>
        </div>
      </div>
      <div className="banner__image">
        <img src="./home.gif" />
      </div>
    </ScreenHeight90>
  );
}
