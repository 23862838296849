import ScreenHeight90 from "../ScreenHeightContainer";
import "./home.css";

export default function Hiring() {
  return (
    <ScreenHeight90 ratio={1} className={"career"}>
      <div className={"career__subtitle"}>Join Our Team</div>
      {/* <div className={"career__title"}>Career openings</div> */}
      <div className={"career___desc"}>
        We&apos;re always on the lookout for creative, talented self-starters to
        join the Sowiz family. Explore our current openings below and apply to
        become part of our dynamic team.
      </div>
      <div className={"career___list"}>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995826681";
          }}
        >
          <div className={"career___list__item__position"}>
            Senior AI Engineer
          </div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>4+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995864399";
          }}
        >
          <div className={"career___list__item__position"}>
            Senior Backend Engineer
          </div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>4+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995863421";
          }}
        >
          <div className={"career___list__item__position"}>
            Backend Engineer
          </div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>2+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995860827";
          }}
        >
          <div className={"career___list__item__position"}>
            Senior FE Engineer
          </div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>4+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995865469";
          }}
        >
          <div className={"career___list__item__position"}>DevOps Engineer</div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>4+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995859837";
          }}
        >
          <div className={"career___list__item__position"}>
            Frontend Engineer
          </div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>3+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995865448";
          }}
        >
          <div className={"career___list__item__position"}>QA Engineer</div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>2+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995862433";
          }}
        >
          <div className={"career___list__item__position"}>Product Manager</div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>1+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
        <div
          className={"career___list__item"}
          onClick={() => {
            window.location.href =
              "https://www.linkedin.com/jobs/view/3995862424";
          }}
        >
          <div className={"career___list__item__position"}>
            Senior Product Manager
          </div>
          <div className={"career___list__item__exp"}>
            <span className={"career___list__item__exp__title"}>
              Experience
            </span>
            <span className={"career___list__item__exp__value"}>3+ Years</span>
          </div>
          <img className={"career___list__item__redirect"} src="/right.png" />
        </div>
      </div>
    </ScreenHeight90>
  );
}
