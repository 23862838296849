import { useEffect, useRef, useState } from "react";

export default function BlurAnimate({ children, style }) {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShow(true);

            observer.unobserve(entry.target);
          }
        });
      });

      observer.observe(ref.current);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={show && "blurUpAnimation"}
    >
      {children}
    </div>
  );
}
