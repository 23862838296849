import { useState } from "react";
import "./home.css";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import ScreenHeight90 from "../ScreenHeightContainer";
import TextUpAnimate from "./animations/TextUp";
import BlurAnimate from "./animations/BlurToVisible";

function Product({
  counter,
  title,
  subtitle,
  desc,
  reverse,
  imgUrl2,
  imgUrl3,
  imgUrl1,
  singleImage,
}) {
  const [show, setShow] = useState();

  return (
    <div className="product">
      <div
        className="product__content"
        style={reverse ? { flexDirection: "row-reverse" } : {}}
      >
        <div className="product__content__details">
          <div className="product__content__details__withCounter">
            <div className="product__content__details__withCounter__counter">
              {counter}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <span className="product__content__details__withCounter__subtitle">
                {subtitle}
              </span>
              <span className="product__content__details__desc">{desc}</span>
            </div>
          </div>
        </div>
        {singleImage ? (
          <div className="product__content__singleImage">
            <img src={singleImage} />
          </div>
        ) : (
          <div className="product__content__image">
            <img
              className={
                !show
                  ? "product__content__image1"
                  : "product__content__image1Revert"
              }
              src={imgUrl1}
            />
            <img className="product__content__image2" src={imgUrl2} />
            <img
              className={
                !show
                  ? "product__content__image3"
                  : "product__content__image3Revert"
              }
              src={imgUrl3}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default function Products(props) {
  return (
    <>
      <TextUpAnimate className="productTitleHandle">
        <div className="product__headline">Our Products</div>
        <div className="product__title">
          Shorten the Awareness-to-Decision Journey and Achieve Unprecedented
          Conversion
        </div>
      </TextUpAnimate>
      <div className="products" id="products">
        <div className="products__container">
          <BlurAnimate>
            <Product
              counter={1}
              title={
                <>
                  Our product delivers <strong>customized innovations</strong>{" "}
                  to meet your unique business needs
                </>
              }
              subtitle={
                <>
                  AI-Powered <strong>Social Wisdom:</strong> Compiled Video
                  Summaries
                </>
              }
              desc={
                <>
                  Harness the power of AI-driven Social Wisdom to provide
                  customers with compiled video summaries that distill key
                  insights from social media trends and discussions. Our
                  approach ensures customers receive concise, relevant content,
                  saving time and enabling smarter decisions in today’s
                  fast-paced digital world.
                </>
              }
              imgUrl1={"pp1.png"}
              imgUrl2={"pp2.png"}
              imgUrl3={"pp3.png"}
            />
          </BlurAnimate>
          <div className="product__divider"></div>
          <BlurAnimate>
            <Product
              counter={2}
              title={
                <>
                  Our product delivers <strong>customized innovations</strong>{" "}
                  to meet your unique business needs
                </>
              }
              subtitle={
                <>
                  Interactive <strong>Video Chatbots</strong> for Engaging
                  Responses
                </>
              }
              desc={
                <>
                  Our interactive chatbots leverage AI to provide customers with
                  dynamic, video-enhanced answers to their questions. Customers
                  are far more likely to be convinced by well-crafted videos
                  available directly within the chatbot, offering clear,
                  effective, and personalized interactions that elevate the
                  overall user experience.
                </>
              }
              reverse={true}
              imgUrl1={"pp2-1.png"}
              imgUrl2={"pp2-2.png"}
              imgUrl3={"pp2-3.png"}
            />
          </BlurAnimate>
          <div className="product__divider"></div>
          <BlurAnimate>
            <Product
              counter={3}
              title={
                <>
                  Our product delivers <strong>customized innovations</strong>{" "}
                  to meet your unique business needs
                </>
              }
              subtitle={
                <>
                  Interactive Referral System for{" "}
                  <strong> Building Trust</strong>
                </>
              }
              desc={
                <>
                  Our AI-powered solution features an interactive referral
                  system that leverages the power of trusted connections.
                  Referrals have proven to be a highly effective way to build
                  trust with customers. By enabling customers to find deals
                  within their network, the likelihood of conversion
                  significantly increases.
                </>
              }
              imgUrl1={"pp3-2.png"}
              imgUrl2={"pp3-1.png"}
              imgUrl3={"pp3-3.png"}
            />
          </BlurAnimate>
          <div className="product__divider"></div>
          <BlurAnimate>
            <Product
              counter={4}
              title={
                <>
                  Our product delivers <strong>customized innovations</strong>{" "}
                  to meet your unique business needs
                </>
              }
              subtitle={
                <>
                  Uncovering <strong>Hidden Insights</strong> with AI-Powered
                  Data
                </>
              }
              desc={
                <>
                  In today’s complex business landscape, identifying customer
                  pain points, spotting inefficiencies, and extracting
                  meaningful insights can be daunting. Our AI-powered,
                  data-driven approach delves into customer behavior to uncover
                  what truly matters. We provide targeted, hyper-personalized
                  follow-ups that address specific user concerns, turning
                  insights into actionable strategies.
                </>
              }
              reverse={true}
              singleImage="/B2B.gif"
            />
          </BlurAnimate>
        </div>
      </div>
    </>
  );
}
