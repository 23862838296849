import ScreenHeight90 from "../ScreenHeightContainer";
import BlurAnimate from "./animations/BlurToVisible";
import TextUpAnimate from "./animations/TextUp";
import "./home.css";

export default function Services() {
  return (
    <ScreenHeight90 ratio={0.9} className="services" id="services">
      <TextUpAnimate>
        <div className="services__text">
          <div className="services__text__title">Our Services</div>
          <div className="services__text__subtitle">
            Delivering Solutions to Achieve{" "}
            <strong>Unprecedented Customer Conversion</strong>
          </div>
        </div>
      </TextUpAnimate>
      <BlurAnimate>
        <div className="services__list">
          <div className="services__list__item">
            <img src="./s4.gif" className="services__list__item__icon" />
            <p className="services__list__item__title">
              Empowering Businesses with All-in-One B2B2C Solutions
            </p>
            <p className="services__list__item__desc">
              Our powerful B2B2C solution streamlines communication, shortening
              the awareness-to-decision journey. With a single, configurable
              platform designed to support every stage of marketing, businesses
              can enjoy ease of use and achieve higher customer conversion
              rates.
            </p>
          </div>
          <div className="services__list__item">
            <img src="./s2.gif" className="services__list__item__icon" />
            <p className="services__list__item__title">
              AI-powered video summaries: Social Wisdom insights.
            </p>
            <p className="services__list__item__desc">
              Harness the power of AI to provide customers with concise video
              summaries enriched with insights from social media trends and
              discussions. Our AI-driven approach distills essential content,
              keeping customers informed and saving time, enabling smarter
              decisions in the ever-evolving digital landscape.
            </p>
          </div>
          <div className="services__list__item">
            <img src="./s3.gif" className="services__list__item__icon" />
            <p className="services__list__item__title">
              Video-Integrated AI Chatbots for Seamless Interaction
            </p>
            <p className="services__list__item__desc">
              Experience a new level of engagement with our AI-driven chatbots
              that integrate video content to provide real-time, personalized
              responses to user inquiries. This fusion of interactive dialogue
              and visual elements creates a more immersive and satisfying user
              experience.
            </p>
          </div>
          <div className="services__list__item">
            <img src="./s1.gif" className="services__list__item__icon" />
            <p className="services__list__item__title">
              Uncovering Hidden Insights with Data-Driven AI
            </p>
            <p className="services__list__item__desc">
              In the complex world of business, identifying customer pain
              points, uncovering inefficiencies, and gaining clear business
              insights can be a challenge. Our AI-powered, data-driven approach
              analyzes customers behavior, revealing what truly matters to them.
              We deliver targeted, hyper-personalized follow-up mechanisms that
              address specific user concerns, transforming insights into
              actionable strategies.
            </p>
          </div>
        </div>
      </BlurAnimate>
    </ScreenHeight90>
  );
}
